import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Styling" description="Guidelines for styling components in Reach UI" mdxType="SEO" />
    <h1 {...{
      "id": "styling"
    }}>{`Styling`}</h1>
    <p><a parentName="p" {...{
        "href": "#including-base-styles"
      }}>{`Including Base Styles`}</a>{` -
`}<a parentName="p" {...{
        "href": "#overriding--adding-new-styles"
      }}>{`Overriding and Adding New Styles`}</a></p>
    <p>{`Styling a Reach component feels similar to styling any native element. There are no themes and you don't have to prescribe to any specific approach to styling. There are some basic styles to make the components usable off-the-shelf, but you can override and add to them with stylesheets, styled components, emotion, glamor, whatever you want.`}</p>
    <h1 {...{
      "id": "including-base-styles"
    }}>{`Including Base Styles`}</h1>
    <p>{`Reach UI uses stylesheets for the components' base styles. You must include these styles in your app for the components to work properly.`}</p>
    <h2 {...{
      "id": "automatically-including-styles"
    }}>{`Automatically Including Styles`}</h2>
    <p>{`A webpack plugin is in development that will automatically bundle the styles so you don't have to.`}</p>
    <h2 {...{
      "id": "skip-including-styles"
    }}>{`Skip including styles`}</h2>
    <p>{`Normally, you'll receive a warning when you forget to include the matching CSS file. If you're implementing all styles yourself, you can disable this warning by setting a root CSS variable matching the package name to 1.`}</p>
    <p><strong parentName="p">{`Please tread carefully`}</strong>{` - in most cases, the styles you import are better to override than remove completely.`}</p>
    <p>{`Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`:root {
  --reach-dialog: 1;
}
`}</code></pre>
    <p>{`If you still see the warning when running tests in Jest, you can skip them by mocking the function that checks the styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`jest.mock("@reach/utils", () => ({
  ...jest.requireActual("@reach/utils"),
  checkStyles: jest.fn()
}));
`}</code></pre>
    <h2 {...{
      "id": "using-a-bundler-webpack-parcel-etc"
    }}>{`Using a Bundler (webpack, parcel, etc.)`}</h2>
    <p>{`If you are using a module bundler like webpack or parcel, you can import them where you import the component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// import the components
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button";

// and the styles
import "@reach/menu-button/styles.css";

// now you're good!
`}</code></pre>
    <p>{`You will need to configure the `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/css-loader"
      }}>{`webpack CSS`}</a>{` loader if you haven't already. Parcel requires no configuration.`}</p>
    <h2 {...{
      "id": "not-using-a-bundler"
    }}>{`Not Using a Bundler`}</h2>
    <p>{`If you’re not using a bundler you can find the styles at:`}</p>
    <p><inlineCode parentName="p">{`your-app/node_modules/@reach/<package-name>/styles.css`}</inlineCode></p>
    <p>{`Include those files however you include the rest of your stylesheets. Alternatively, you can use a CDN like Unpkg, but this is not recommended for production apps.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link
  rel="stylesheet"
  type="text/css"
  href="https://unpkg.com/@reach/<package-name>@<version>/styles.css"
/>
`}</code></pre>
    <h1 {...{
      "id": "overriding--adding-new-styles"
    }}>{`Overriding & Adding New Styles`}</h1>
    <p>{`You should be able to style Reach UI components like any other element in your app. If not, report a bug!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Emotion and styled components
let YourMenuList = styled(MenuList)\`
  border: solid 2px black;
  background: black;
  color: red;
  > [data-reach-menu-item][data-selected] {
    background: red;
    color: white;
  }
\`

// normal className
<MenuList className="yep"/>

// normal style
<MenuList style={sure}/>

// glamor CSS prop
<MenuList css={absolutely}/>
`}</code></pre>
    <h2 {...{
      "id": "css-selectors"
    }}>{`CSS Selectors`}</h2>
    <p>{`Because Reach UI uses regular stylesheets for its own styles you can override them like any other element. All styles use the lowest possible "specificity score", so as long as you include the component styles before your own app styles you should not run into any specificity problems.`}</p>
    <p>{`There are two types of selectors, "element" and "pseudo". The documentation for each component lists the selectors that apply to the component, but we're using `}<inlineCode parentName="p">{`MenuItem`}</inlineCode>{` from `}<inlineCode parentName="p">{`@reach/menu-button`}</inlineCode>{` as an example here.`}</p>
    <h3 {...{
      "id": "element-selectors"
    }}>{`"Element" Selectors`}</h3>
    <p>{`Each component has a `}<inlineCode parentName="p">{`data-reach-*`}</inlineCode>{` attribute on the underlying DOM element that you can think of as the "element" for the component. For example, to style all dropdown menu items, you can use this CSS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-item] {
  color: blue;
}
`}</code></pre>
    <h3 {...{
      "id": "pseudo-selectors"
    }}>{`"Pseudo" Selectors`}</h3>
    <p>{`There are certain states an element can go into like "selected". To target these styles use a "pseudo" selector (more like a pseudo-pseudo selector!). For example, to style all dropdown menu items in their highlighted, or selected state, use this CSS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-item][data-selected] {
  background: red;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      